import React from 'react';
import { motion } from 'framer-motion';
import { Music2, Speaker, Calendar } from 'lucide-react';
import { useScrollTop } from '../hooks/useScrollTop';

const Portfolio = () => {
  useScrollTop();

  return (
    <div className="pt-16">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20"
      >
        <h1 className="text-4xl md:text-5xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">
          Portfolio
        </h1>
        
        <div className="grid md:grid-cols-3 gap-8 mt-12">
          {portfolioItems.map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="bg-black/50 backdrop-blur-sm rounded-lg p-6 border border-purple-500/20"
            >
              {item.icon}
              <h3 className="text-xl font-semibold my-4">{item.title}</h3>
              <p className="text-gray-300">{item.description}</p>
            </motion.div>
          ))}
        </div>

        <div className="mt-16">
          <h2 className="text-3xl font-bold mb-8">Referenzen</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {references.map((ref, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                className="bg-black/50 backdrop-blur-sm rounded-lg p-6 border border-purple-500/20"
              >
                <h3 className="text-xl font-semibold mb-2">{ref.venue}</h3>
                <p className="text-gray-400">{ref.location}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

const portfolioItems = [
  {
    icon: <Music2 className="w-8 h-8 text-purple-500" />,
    title: "Musikauswahl",
    description: "Von Charts über Classics bis zu speziellen Musikwünschen - flexibel und individuell auf Ihre Veranstaltung abgestimmt."
  },
  {
    icon: <Speaker className="w-8 h-8 text-purple-500" />,
    title: "Equipment",
    description: "Modernste Ton- und Lichttechnik für perfekten Sound und stimmungsvolle Atmosphäre."
  },
  {
    icon: <Calendar className="w-8 h-8 text-purple-500" />,
    title: "Events",
    description: "Hochzeiten, Firmenfeiern, Geburtstage, Clubevents & Partys - für jeden Anlass die passende musikalische Untermalung."
  }
];

const references = [
  {
    venue: "Club Moment",
    location: "Bad Grönebach"
  },
  {
    venue: "ERGO Group",
    location: ""
  },
  {
    venue: "Baywa AG",
    location: ""
  },
  {
    venue: "SMP Deutschland",
    location: ""
  },
  {
    venue: "Swietelsky-Faber",
    location: ""
  },
  {
    venue: "Goaßstoi",
    location: "Großmehring"
  },
  {
    venue: "Birkenheide",
    location: "Geisenfeld"
  },
  {
    venue: "Herrler Gourmet",
    location: "Beilngries"
  },
  {
    venue: "Postwirt",
    location: "Kipfenberg"
  },
  {
    venue: "Eventtenne",
    location: "Pfaffenhofen"
  }
];

export default Portfolio;