import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Calendar, Music, Star, MapPin, Phone, Mail, Speaker, Mic, Music2 } from 'lucide-react';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <>
      <div className="relative min-h-screen">
        {/* Video Background */}
        <video
          autoPlay
          loop
          muted
          playsInline
          className="fixed inset-0 w-full h-full object-cover z-0 max-w-[100vw] max-h-[100vh]"
          style={{
            position: 'fixed',
            right: '0',
            bottom: '0',
            minWidth: '100%',
            minHeight: '100%',
            width: 'auto',
            height: 'auto'
          }}
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          poster="/images/dj-video-poster.jpg"
          className="fixed inset-0 w-full h-full object-cover z-0"
        >
          <source src="/videos/dj-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-black/30 via-black/20 to-black z-10" />

        {/* Content */}
        <div className="relative z-20 min-h-screen flex flex-col justify-center items-center text-white px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center max-w-4xl px-4"
          >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="mb-6 sm:mb-8"
            >
              <img
                src="/images/logo.png"
                alt="DJ Chrizz Logo"
                className="mx-auto h-24 sm:h-28 md:h-32 w-auto drop-shadow-[0_5px_5px_rgba(0,0,0,0.7)]"
              />
            </motion.div>
            <motion.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500 drop-shadow-[0_5px_5px_rgba(0,0,0,0.7)] px-2 leading-tight"
            >
              HOCHZEITS & EVENT DJ BAYERN
            </motion.h1>
            <motion.p 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              className="text-xl sm:text-2xl md:text-3xl mb-4 sm:mb-6 text-gray-200 drop-shadow-[0_4px_4px_rgba(0,0,0,0.8)] leading-tight"
            >
              FÜR DEIN EVENT GIBT ES KEINE ZWEITE CHANCE
            </motion.p>
            <motion.p 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.6 }}
              className="text-lg sm:text-xl mb-8 sm:mb-10 text-gray-300 px-4 drop-shadow-[0_3px_3px_rgba(0,0,0,0.8)] leading-relaxed"
            >
              Über 22 Jahre Erfahrung in der Gestaltung unvergesslicher Momente
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.8 }}
            >
              <Link to="/booking">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="glow-button text-white px-8 py-3 rounded-full font-medium flex items-center space-x-2 mx-auto drop-shadow-[0_4px_4px_rgba(0,0,0,0.5)]"
                >
                  <span className="flex items-center space-x-2">
                    <span>Jetzt buchen</span>
                    <ArrowRight className="w-5 h-5" />
                  </span>
                </motion.button>
              </Link>
            </motion.div>
          </motion.div>
        </div>

        {/* Scroll Indicator */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.2, duration: 0.8 }}
          className="absolute bottom-8 left-1/2 transform -translate-x-1/2 z-20"
        >
          <motion.div
            animate={{ y: [0, 10, 0] }}
            transition={{ repeat: Infinity, duration: 1.5 }}
            className="w-6 h-10 border-2 border-white rounded-full flex justify-center drop-shadow-[0_2px_2px_rgba(0,0,0,0.5)]"
          >
            <motion.div className="w-1 h-2 bg-white rounded-full mt-2" />
          </motion.div>
        </motion.div>
      </div>

      {/* Services Section */}
      <section className="relative z-10 py-20 bg-gradient-to-b from-black to-purple-900/20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">Dienstleistungen</h2>
            <div className="grid md:grid-cols-3 gap-8">
              {services.map((service, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="service-card bg-black/50 backdrop-blur-sm rounded-lg p-6 border border-purple-500/20"
                >
                  {service.icon}
                  <h3 className="text-xl font-semibold mb-2">{service.name}</h3>
                  <p className="text-gray-400">{service.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>

      {/* Experience Section */}
      <section className="relative z-10 py-20 bg-black">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-6">Über DJ Chrizz</h2>
            <p className="text-lg text-gray-300 max-w-3xl mx-auto">
              Vor über 22 Jahren begann meine DJ-Karriere bei kleinen Geburtstagsfeiern und führte mich durch Clubs und Discos in Deutschland, Österreich und der Schweiz. Heute konzentriere ich mich auf mobile Veranstaltungen mit eigenem professionellen Equipment und biete maßgeschneiderte Unterhaltung für jeden Anlass.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Coverage Area */}
      <section className="relative z-10 py-20 bg-gradient-to-b from-purple-900/20 to-black">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">Einsatzgebiet</h2>
            <div className="grid md:grid-cols-2 gap-8">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
                className="service-card bg-black/50 backdrop-blur-sm rounded-lg p-8 border border-purple-500/20"
              >
                <div className="flex items-center mb-6">
                  <MapPin className="w-8 h-8 text-purple-500" />
                  <h3 className="text-2xl font-semibold ml-3">Verfügbar in ganz Bayern und Umgebung</h3>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {locations.map((location, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.1 }}
                      className="location-badge flex items-center space-x-2 bg-purple-900/20 rounded-lg p-3"
                    >
                      <div className="w-2 h-2 rounded-full bg-purple-500" />
                      <span className="text-gray-200">{location}</span>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
              
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
                className="service-card bg-black/50 backdrop-blur-sm rounded-lg p-8 border border-purple-500/20"
              >
                <div className="flex items-center mb-6">
                  <Phone className="w-8 h-8 text-purple-500" />
                  <h3 className="text-2xl font-semibold ml-3">Kontakt</h3>
                </div>
                <div className="space-y-6">
                  <div className="bg-purple-900/20 rounded-lg p-4">
                    <p className="text-xl font-semibold text-gray-200">Christian Ewerling</p>
                    <p className="text-gray-300 mt-2">Theresienstr. 3a</p>
                    <p className="text-gray-300">85098 Großmehring</p>
                  </div>
                  <div className="space-y-4">
                    <a
                      href="tel:015123735195"
                      className="flex items-center space-x-3 bg-purple-900/20 rounded-lg p-4 hover:bg-purple-900/30 transition-colors group"
                    >
                      <Phone className="w-5 h-5 text-purple-500 group-hover:scale-110 transition-transform" />
                      <span className="text-gray-200 group-hover:text-white transition-colors">015123735195</span>
                    </a>
                    <a
                      href="mailto:info@djchrizz.de"
                      className="flex items-center space-x-3 bg-purple-900/20 rounded-lg p-4 hover:bg-purple-900/30 transition-colors group"
                    >
                      <Mail className="w-5 h-5 text-purple-500 group-hover:scale-110 transition-transform" />
                      <span className="text-gray-200 group-hover:text-white transition-colors">info@djchrizz.de</span>
                    </a>
                  </div>
                </div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="relative z-10 py-20 bg-black">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">Was andere sagen</h2>
            <div className="grid md:grid-cols-3 gap-8">
              {testimonials.map((testimonial, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="testimonial-card bg-black/50 backdrop-blur-sm rounded-lg p-6 border border-purple-500/20"
                >
                  <div className="flex space-x-1 mb-4">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} className="w-5 h-5 text-yellow-500 fill-yellow-500" />
                    ))}
                  </div>
                  <p className="text-gray-300 mb-4">{testimonial.text}</p>
                  <div>
                    <p className="font-semibold">{testimonial.name}</p>
                    <p className="text-sm text-gray-400">{testimonial.title}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>
    </>
  );
};

const services = [
  {
    icon: <Music2 className="w-8 h-8 text-purple-500 mb-4" />,
    name: "Professionelle Musik",
    description: "Vielseitige Musikauswahl für jeden Geschmack und Anlass, von Hochzeiten bis Firmenfeiern."
  },
  {
    icon: <Speaker className="w-8 h-8 text-purple-500 mb-4" />,
    name: "Sound- & Lichttechnik",
    description: "Hochwertige Beschallungsanlage und stimmungsvolle Lichteffekte für die perfekte Atmosphäre."
  },
  {
    icon: <Mic className="w-8 h-8 text-purple-500 mb-4" />,
    name: "Moderation",
    description: "Professionelle Moderation Ihrer Veranstaltung und flexible Anpassung an Ihre Wünsche."
  }
];

const locations = [
  "München",
  "Ingolstadt",
  "Nürnberg",
  "Passau",
  "Salzburg",
  "Regensburg",
  "Augsburg",
  "Landshut"
];

const testimonials = [
  {
    text: "DJ Chrizz hat unsere Hochzeit zu einem unvergesslichen Erlebnis gemacht. Die Musikauswahl war perfekt und die Stimmung grandios!",
    name: "Sarah & Michael",
    title: "Hochzeitspaar"
  },
  {
    text: "Professionell, pünktlich und eine perfekte Musikauswahl. Die Firmenfeier war ein voller Erfolg!",
    name: "Thomas",
    title: "Eventmanager"
  },
  {
    text: "Tolle Moderation und super Stimmung auf unserer Geburtstagsfeier. Alle Gäste waren begeistert!",
    name: "Lisa",
    title: "Privatkundin"
  }
];

export default Hero;