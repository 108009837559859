import React from 'react';
import { motion } from 'framer-motion';
import { Calendar, MapPin } from 'lucide-react';
import { useScrollTop } from '../hooks/useScrollTop';

const Events = () => {
  useScrollTop();

  return (
    <div className="pt-16 lg:pt-0">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20"
      >
        <h1 className="text-4xl md:text-5xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">
          anstehende Veranstaltungen
        </h1>

        <div className="grid gap-8">
          {events.map((event, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="bg-black/50 backdrop-blur-sm rounded-lg p-6 border border-purple-500/20"
            >
              <div>
                <h2 className="text-2xl font-semibold mb-2">{event.name}</h2>
                <div className="space-y-2">
                  <div className="flex items-center text-gray-300">
                    <Calendar className="w-5 h-5 text-purple-500 mr-2" />
                    <span>{event.date}</span>
                  </div>
                  <div className="flex items-center text-gray-300">
                    <MapPin className="w-5 h-5 text-purple-500 mr-2" />
                    <span>{event.location}</span>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

const events = [
  {
    name: "Ballermann vs 90er",
    date: "03. Mai 2025",
    location: "im Goaßstoi"
  },
  {
    name: "Hüttengaudi Volksfest",
    date: "06. Juni 2025",
    location: "Lohhof"
  },
  {
    name: "Abriss zum Spitz",
    date: "05. September 2025",
    location: "Pfaffenhofen"
  }
];

export default Events;