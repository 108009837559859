import React from 'react';
import { motion } from 'framer-motion';
import { Calendar, User, Clock } from 'lucide-react';
import { useScrollTop } from '../hooks/useScrollTop';
import BlogModal from '../components/BlogModal';
import { useState } from 'react';

interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  content: string;
  date: string;
  slug: string;
}

const blogPosts: BlogPost[] = [
  {
    id: '1',
    title: 'Die perfekte Hochzeitsplaylist',
    excerpt: 'Tipps und Tricks für eine unvergessliche musikalische Reise durch Ihren besonderen Tag.',
    content: `Die Musik auf einer Hochzeit ist einer der wichtigsten Faktoren für eine gelungene Feier. Hier sind meine Top-Tipps für die perfekte Hochzeitsplaylist:

1. Der erste Tanz
   - Wählen Sie einen Song, der Ihnen beiden etwas bedeutet
   - Achten Sie auf die Länge des Songs (ideal sind 3-4 Minuten)
   - Üben Sie den Tanz vorher

2. Dinner-Musik
   - Dezente Hintergrundmusik
   - Jazz und Lounge-Musik eignen sich hervorragend
   - Lautstärke so wählen, dass Gespräche möglich sind

3. Party-Phase
   - Mit bekannten Hits starten
   - Verschiedene Genres mischen
   - Auf die Energie im Raum achten

4. Wichtige Aspekte
   - Wünsche der Gäste berücksichtigen
   - Generationsübergreifende Musikauswahl
   - Flexible Playlist für spontane Änderungen`,
    date: '2025-03-15',
    slug: 'perfekte-hochzeitsplaylist'
  },
  {
    id: '2',
    title: 'Moderne vs. Klassische Partyhits',
    excerpt: 'Eine Analyse der beliebtesten Tanzmusik und wie man die richtige Balance findet.',
    content: `Die Kunst des DJings liegt oft in der perfekten Balance zwischen modernen Hits und zeitlosen Klassikern. Hier ist meine Analyse:

1. Moderne Hits
   - Aktuelle Charts
   - Elektronische Musik
   - Remixe bekannter Songs

2. Klassische Partyhits
   - 80er und 90er Hits
   - Rock-Klassiker
   - Deutsche Schlager

3. Die richtige Mischung
   - Zielgruppe beachten
   - Übergänge geschickt wählen
   - Energie-Level steuern

4. Timing ist alles
   - Klassiker für die Hauptparty-Zeit
   - Moderne Hits zum Aufwärmen
   - Publikum "lesen" können`,
    date: '2025-03-10',
    slug: 'moderne-vs-klassische-partyhits'
  },
  {
    id: '3',
    title: 'Equipment Guide für DJs',
    excerpt: 'Ein Einblick in die wichtigsten technischen Aspekte für professionelles DJing.',
    content: `Als professioneller DJ ist die richtige Ausrüstung entscheidend. Hier ist mein Guide für essentielles DJ-Equipment:

1. Controller & Mixer
   - Professionelle DJ-Controller
   - Hochwertige Mixer
   - Backup-Systeme

2. Soundsystem
   - Aktive PA-Systeme
   - Subwoofer
   - Monitoring

3. Lichtanlage
   - LED-Systeme
   - Moving Heads
   - Laser und Effekte

4. Zusatzequipment
   - Backup-Laptop
   - Kabel und Adapter
   - Mikrophone`,
    date: '2025-03-05',
    slug: 'equipment-guide-fuer-djs'
  }
];

const Blog = () => {
  useScrollTop();
  const [selectedPost, setSelectedPost] = useState<BlogPost | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePostClick = (post: BlogPost) => {
    setSelectedPost(post);
    setIsModalOpen(true);
  };

  return (
    <div className="pt-16 lg:pt-0">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20"
      >
        <h1 className="text-4xl md:text-5xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">
          Blog
        </h1>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post) => (
            <motion.article
              key={post.id}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="bg-black/50 backdrop-blur-sm rounded-lg overflow-hidden border border-purple-500/20 hover:border-purple-500/40 transition-all duration-300 group cursor-pointer hover:transform hover:scale-[1.02]"
              onClick={() => handlePostClick(post)}
            >
                <div className="p-6 h-full flex flex-col">
                  <div className="flex items-center space-x-4 text-sm text-gray-400 mb-4">
                    <span className="flex items-center">
                      <Calendar className="w-4 h-4 mr-1" />
                      {new Date(post.date).toLocaleDateString('de-DE')}
                    </span>
                  </div>
                  <h2 className="text-xl font-semibold mb-3 group-hover:text-purple-400 transition-colors">
                    {post.title}
                  </h2>
                  <p className="text-gray-400 mb-4 flex-grow">
                    {post.excerpt}
                  </p>
                  <div className="flex items-center text-purple-500 font-medium group-hover:translate-x-2 transition-transform">
                    Weiterlesen
                    <svg
                      className="w-4 h-4 ml-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </div>
                </div>
            </motion.article>
          ))}
        </div>
        
        <BlogModal
          post={selectedPost}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </motion.div>
    </div>
  );
};

export default Blog;