import React from 'react';
import { Link } from 'react-router-dom';
import { Instagram, Facebook, Mail, Phone } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-black/30 backdrop-blur-sm border-t border-purple-500/20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">DJ Chrizz</h3>
            <p className="text-gray-300">
              Professioneller DJ für Ihre Veranstaltung
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Navigation</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="text-gray-300 hover:text-white transition-colors">Home</Link>
              </li>
              <li>
                <Link to="/about" className="text-gray-300 hover:text-white transition-colors">About</Link>
              </li>
              <li>
                <Link to="/portfolio" className="text-gray-300 hover:text-white transition-colors">Portfolio</Link>
              </li>
              <li>
                <Link to="/blog" className="text-gray-300 hover:text-white transition-colors">Blog</Link>
              </li>
              <li>
                <Link to="/booking" className="text-gray-300 hover:text-white transition-colors">Booking</Link>
              </li>
              <li>
                <Link to="/events" className="text-gray-300 hover:text-white transition-colors">Events</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Kontakt</h3>
            <ul className="space-y-2">
              <li>
                <a href="tel:015123735195" className="text-gray-300 hover:text-white transition-colors flex items-center">
                  <Phone className="w-4 h-4 mr-2" />
                  015123735195
                </a>
              </li>
              <li>
                <a href="mailto:info@djchrizz.de" className="text-gray-300 hover:text-white transition-colors flex items-center">
                  <Mail className="w-4 h-4 mr-2" />
                  info@djchrizz.de
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Social Media</h3>
            <div className="flex space-x-4">
              <a
                href="https://www.instagram.com/djchrizz84/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-purple-500 transition-colors"
              >
                <Instagram className="w-6 h-6" />
              </a>
              <a
                href="https://www.facebook.com/christian.ewerling.1"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-300 hover:text-purple-500 transition-colors"
              >
                <Facebook className="w-6 h-6" />
              </a>
              <a
                href="tel:015123735195"
                className="text-gray-300 hover:text-purple-500 transition-colors"
              >
                <Phone className="w-6 h-6" />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-purple-500/20 flex flex-col md:flex-row justify-between items-center">
          <p className="text-gray-300">© 2025 DJ Chrizz. Alle Rechte vorbehalten.</p>
          <div className="flex space-x-4 mt-4 md:mt-0">
            <Link to="/privacy" className="text-gray-300 hover:text-white transition-colors">Datenschutz</Link>
            <Link to="/imprint" className="text-gray-300 hover:text-white transition-colors">Impressum</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;