import React from 'react';
import { motion } from 'framer-motion';
import { useScrollTop } from '../hooks/useScrollTop';
import { Music2, Calendar, Users, Trophy } from 'lucide-react';

const About = () => {
  useScrollTop();

  return (
    <div className="pt-16 lg:pt-0">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20"
      >
        <h1 className="text-4xl md:text-5xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">
          zu mir ...
        </h1>

        {/* Hero Section with Image */}
        <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
            className="relative"
          >
            <div className="rounded-2xl overflow-hidden shadow-2xl border border-purple-500/20">
              <img
                src="/images/dj-chrizz.jpeg"
                alt="DJ Chrizz at the booth"
                className="w-full h-auto"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
            </div>
            <div className="absolute -bottom-4 -right-4 bg-black/80 backdrop-blur-sm px-6 py-3 rounded-lg border border-purple-500/20">
              <p className="text-sm font-medium text-purple-400">DJ Chrizz</p>
              <p className="text-xs text-gray-400">Ihr Hochzeitsprofi</p>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.4 }}
            className="space-y-6"
          >
            <p className="text-xl text-gray-300">
              Mit über 22 Jahren Erfahrung als professioneller DJ bringe ich die perfekte Mischung aus Expertise und Leidenschaft für Musik mit.
            </p>
          </motion.div>
        </div>

        {/* Stats Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
          className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-16"
        >
          <div className="bg-black/50 backdrop-blur-sm rounded-lg p-6 border border-purple-500/20">
            <Calendar className="w-8 h-8 text-purple-500 mb-3" />
            <div className="text-2xl font-bold text-white">22+</div>
            <div className="text-sm text-gray-400">Jahre Erfahrung</div>
          </div>
          <div className="bg-black/50 backdrop-blur-sm rounded-lg p-6 border border-purple-500/20">
            <Music2 className="w-8 h-8 text-purple-500 mb-3" />
            <div className="text-2xl font-bold text-white">500+</div>
            <div className="text-sm text-gray-400">Events</div>
          </div>
          <div className="bg-black/50 backdrop-blur-sm rounded-lg p-6 border border-purple-500/20">
            <Users className="w-8 h-8 text-purple-500 mb-3" />
            <div className="text-2xl font-bold text-white">50k+</div>
            <div className="text-sm text-gray-400">Zufriedene Gäste</div>
          </div>
          <div className="bg-black/50 backdrop-blur-sm rounded-lg p-6 border border-purple-500/20">
            <Trophy className="w-8 h-8 text-purple-500 mb-3" />
            <div className="text-2xl font-bold text-white">90%</div>
            <div className="text-sm text-gray-400">Kundenzufriedenheit</div>
          </div>
        </motion.div>

        {/* Additional Info */}
        <div className="grid md:grid-cols-2 gap-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8 }}
            className="bg-black/50 backdrop-blur-sm rounded-lg p-8 border border-purple-500/20"
          >
            <h2 className="text-2xl font-semibold mb-4">Meine Geschichte</h2>
            <p className="text-gray-300">
              Was als Hobby bei kleinen Geburtstagsfeiern begann, entwickelte sich zu einer erfolgreichen Karriere durch Clubs und Diskotheken in Deutschland, Österreich und der Schweiz. Heute konzentriere ich mich auf mobile Veranstaltungen mit eigenem professionellen Equipment.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1 }}
            className="bg-black/50 backdrop-blur-sm rounded-lg p-8 border border-purple-500/20"
          >
            <h2 className="text-2xl font-semibold mb-4">Meine Mission</h2>
            <p className="text-gray-300">
              Mein Ziel ist es, jede Veranstaltung zu einem unvergesslichen Erlebnis zu machen. Mit modernster Technik und einem feinen Gespür für die richtige Musik zum richtigen Zeitpunkt sorge ich für die perfekte Atmosphäre.
            </p>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default About;