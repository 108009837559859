import { z } from 'zod';

// Helper function to validate German phone numbers
const isValidGermanPhone = (phone: string) => {
  const germanPhoneRegex = /^(\+49|0)[1-9]\d{9,14}$/;
  return germanPhoneRegex.test(phone.replace(/\s/g, ''));
};

// Helper function to validate future date
const isFutureDate = (date: string) => {
  const inputDate = new Date(date);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return inputDate >= today;
};

export const bookingSchema = z.object({
  name: z.string()
    .min(2, 'Name muss mindestens 2 Zeichen lang sein')
    .max(100, 'Name darf maximal 100 Zeichen lang sein'),
  
  email: z.string()
    .email('Ungültige E-Mail-Adresse')
    .min(5, 'E-Mail muss mindestens 5 Zeichen lang sein')
    .max(100, 'E-Mail darf maximal 100 Zeichen lang sein'),
  
  company: z.string()
    .max(100, 'Firmenname darf maximal 100 Zeichen lang sein')
    .optional(),

  phone: z.string()
    .refine(isValidGermanPhone, 'Ungültige deutsche Telefonnummer'),

  eventType: z.string()
    .min(3, 'Veranstaltungstyp muss mindestens 3 Zeichen lang sein')
    .max(100, 'Veranstaltungstyp darf maximal 100 Zeichen lang sein'),
  
  date: z.string()
    .refine(isFutureDate, 'Das Datum muss in der Zukunft liegen'),
  
  startTime: z.string()
    .min(1, 'Startzeit ist erforderlich'),
  
  endTime: z.string()
    .min(1, 'Endzeit ist erforderlich'),
  
  budget: z.string()
    .min(3, 'Budget muss angegeben werden')
    .max(20, 'Budget ist zu lang'),

  guests: z.number()
    .min(10, 'Mindestens 10 Gäste erforderlich')
    .max(1000, 'Maximale Gästeanzahl ist 1000'),

  message: z.string()
    .min(10, 'Bitte geben Sie Informationen zu Ihrer Veranstaltung an')
    .max(2000, 'Nachricht darf maximal 2000 Zeichen lang sein')
});

export type BookingFormData = z.infer<typeof bookingSchema>;