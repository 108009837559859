import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

const Privacy = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return (
    <div className="pt-16 lg:pt-0">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20"
      >
        <h1 className="text-4xl md:text-5xl font-bold mb-12 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500 text-center">
          Datenschutzerklärung
        </h1>
        
        <div className="prose prose-lg prose-invert max-w-none">
          <div className="bg-black/30 backdrop-blur-sm rounded-lg p-8 border border-purple-500/20 mb-8">
            <h2 className="text-3xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400 mt-0">1. Datenschutz auf einen Blick</h2>
            <div className="space-y-6">
              <div>
                <h3 className="text-2xl font-semibold mb-4 text-white">Allgemeine Hinweise</h3>
                <p className="text-gray-300">
                  Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
                </p>
              </div>

              <div>
                <h3 className="text-2xl font-semibold mb-4 text-white">Datenerfassung auf unserer Website</h3>
                <div className="space-y-4">
                  <h4 className="text-xl font-medium text-purple-400">Wer ist verantwortlich für die Datenerfassung?</h4>
                  <p className="text-gray-300">
                    Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.
                  </p>

                  <h4 className="text-xl font-medium text-purple-400">Wie erfassen wir Ihre Daten?</h4>
                  <p className="text-gray-300">
                    Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
                  </p>
                  <p className="text-gray-300">
                    Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.
                  </p>

                  <h4 className="text-xl font-medium text-purple-400">Wofür nutzen wir Ihre Daten?</h4>
                  <p className="text-gray-300">
                    Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
                  </p>

                  <h4 className="text-xl font-medium text-purple-400">Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
                  <p className="text-gray-300">
                    Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-black/30 backdrop-blur-sm rounded-lg p-8 border border-purple-500/20 mb-8">
            <h2 className="text-3xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400 mt-0">2. Allgemeine Hinweise und Pflichtinformationen</h2>
            <div className="space-y-6">
              <div>
                <h3 className="text-2xl font-semibold mb-4 text-white">Datenschutz</h3>
                <p className="text-gray-300">
                  Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                </p>
              </div>

              <div>
                <h3 className="text-2xl font-semibold mb-4 text-white">Hinweis zur verantwortlichen Stelle</h3>
                <p className="text-gray-300">
                  Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                </p>
                <p className="text-gray-300">
                  DJ Chrizz<br />
                  Christian Ewerling<br />
                  Theresienstraße 3a<br />
                  85098 Großmehring<br /><br />
                  Telefon: 015123735195<br />
                  E-Mail: info@djchrizz.de
                </p>
              </div>

              <div>
                <h3 className="text-2xl font-semibold mb-4 text-white">Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
                <p className="text-gray-300">
                  Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                </p>
              </div>

              <div>
                <h3 className="text-2xl font-semibold mb-4 text-white">Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</h3>
                <p className="text-gray-300">
                  Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO).
                </p>
                <p className="text-gray-300">
                  Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Wenn Sie widersprechen, werden Ihre personenbezogenen Daten anschließend nicht mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).
                </p>
              </div>
            </div>
          </div>

          <div className="bg-black/30 backdrop-blur-sm rounded-lg p-8 border border-purple-500/20 mb-8">
            <h2 className="text-3xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400 mt-0">3. Datenerfassung auf unserer Website</h2>
            <div className="space-y-6">
              <div>
                <h3 className="text-2xl font-semibold mb-4 text-white">Cookies</h3>
                <p className="text-gray-300">
                  Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
                </p>
                <p className="text-gray-300">
                  Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies". Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
                </p>
              </div>

              <div>
                <h3 className="text-2xl font-semibold mb-4 text-white">Server-Log-Dateien</h3>
                <p className="text-gray-300">
                  Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
                </p>
                <ul className="list-disc pl-6 text-gray-300">
                  <li>Browsertyp und Browserversion</li>
                  <li>verwendetes Betriebssystem</li>
                  <li>Referrer URL</li>
                  <li>Hostname des zugreifenden Rechners</li>
                  <li>Uhrzeit der Serveranfrage</li>
                  <li>IP-Adresse</li>
                </ul>
              </div>

              <div>
                <h3 className="text-2xl font-semibold mb-4 text-white">Anfrage per E-Mail, Telefon oder Telefax</h3>
                <p className="text-gray-300">
                  Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-black/30 backdrop-blur-sm rounded-lg p-8 border border-purple-500/20 mb-8">
            <h2 className="text-3xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400 mt-0">4. Soziale Medien</h2>
            <div className="space-y-6">
              <div>
                <h3 className="text-2xl font-semibold mb-4 text-white">Social-Media-Plugins mit Shariff</h3>
                <p className="text-gray-300">
                  Auf unseren Seiten werden Plugins von sozialen Medien verwendet (z. B. Facebook, Instagram). Die Plugins können Sie in der Regel anhand der jeweiligen Social-Media-Logos erkennen.
                </p>
                <p className="text-gray-300">
                  Um den Datenschutz auf unserer Website zu gewährleisten, verwenden wir diese Plugins nur zusammen mit der sogenannten „Shariff"-Lösung. Diese Anwendung verhindert, dass die auf unserer Website integrierten Plugins Daten schon beim ersten Betreten der Seite an den jeweiligen Anbieter übertragen.
                </p>
              </div>

              <div>
                <h3 className="text-2xl font-semibold mb-4 text-white">Facebook Plugins (Like & Share-Button)</h3>
                <p className="text-gray-300">
                  Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook integriert. Die Facebook Plugins erkennen Sie an dem Facebook-Logo oder dem „Like-Button" („Gefällt mir") auf unserer Seite. Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt.
                </p>
              </div>

              <div>
                <h3 className="text-2xl font-semibold mb-4 text-white">Instagram Plugin</h3>
                <p className="text-gray-300">
                  Auf unseren Seiten sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden angeboten durch die Instagram Inc., 1601 Willow Road, Menlo Park, CA 94025, USA integriert.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-black/30 backdrop-blur-sm rounded-lg p-8 border border-purple-500/20 mb-8">
            <h2 className="text-3xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400 mt-0">5. Analyse-Tools und Werbung</h2>
            <p className="text-gray-300">
              Diese Website nutzt keine Analyse-Tools oder Werbetools von Drittanbietern.
            </p>
          </div>
          
          <p className="text-sm text-gray-400 mt-8 text-center">
            Quelle: <a href="https://www.e-recht24.de" target="_blank" rel="noopener noreferrer" className="text-purple-400 hover:text-purple-300">https://www.e-recht24.de</a>
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default Privacy;