import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Calendar, ChevronLeft } from 'lucide-react';

interface BlogPost {
  title: string;
  date: string;
  content: string;
}

interface BlogModalProps {
  post: BlogPost | null;
  isOpen: boolean;
  onClose: () => void;
}

const BlogModal = ({ post, isOpen, onClose }: BlogModalProps) => {
  if (!post) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50"
          />

          {/* Modal */}
          <motion.div
            initial={{ opacity: 0, scale: 0.9, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.9, y: 20 }}
            transition={{ type: "spring", damping: 20 }}
            className="fixed inset-4 md:inset-20 bg-black/95 backdrop-blur-sm rounded-lg z-50 overflow-hidden border border-purple-500/20"
          >
            {/* Header */}
            <div className="sticky top-0 bg-black/80 backdrop-blur-sm border-b border-purple-500/20 p-4 flex items-center justify-between">
              <button
                onClick={onClose}
                className="flex items-center text-gray-400 hover:text-white transition-colors"
              >
                <ChevronLeft className="w-5 h-5 mr-1" />
                Zurück
              </button>
              <button
                onClick={onClose}
                className="p-2 rounded-full bg-black/50 text-gray-400 hover:text-white hover:bg-purple-500/20 transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            {/* Content */}
            <div className="h-[calc(100%-4rem)] overflow-y-auto">
              <article className="max-w-3xl mx-auto">
                <div className="p-6 md:p-8">
                  <h1 className="text-2xl md:text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">
                    {post.title}
                  </h1>

                  <div className="flex items-center space-x-4 text-sm text-gray-400 mb-8">
                    <span className="flex items-center">
                      <Calendar className="w-4 h-4 mr-1" />
                      {new Date(post.date).toLocaleDateString('de-DE')}
                    </span>
                  </div>

                  <div className="prose prose-invert prose-purple max-w-none">
                    {post.content.split('\n\n').map((paragraph, index) => {
                      if (paragraph.startsWith('#')) {
                        // Handle headings
                        const level = paragraph.match(/^#+/)[0].length;
                        const text = paragraph.replace(/^#+\s/, '');
                        const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;
                        return (
                          <HeadingTag key={index} className="text-xl md:text-2xl font-bold mt-8 mb-4 text-purple-400">
                            {text}
                          </HeadingTag>
                        );
                      } else if (paragraph.startsWith('-')) {
                        // Handle lists
                        return (
                          <ul key={index} className="list-disc pl-6 space-y-2 my-4 text-gray-300">
                            {paragraph.split('\n').map((item, i) => (
                              <li key={i}>{item.replace(/^-\s/, '')}</li>
                            ))}
                          </ul>
                        );
                      } else if (paragraph.match(/^\d\./)) {
                        // Handle numbered lists
                        return (
                          <div key={index} className="my-6">
                            <h3 className="text-lg font-semibold text-purple-400 mb-3">
                              {paragraph.split('\n')[0].replace(/^\d\.\s/, '')}
                            </h3>
                            <ul className="list-disc pl-6 space-y-2 text-gray-300">
                              {paragraph.split('\n').slice(1).map((item, i) => (
                                <li key={i}>{item.replace(/^-\s/, '')}</li>
                              ))}
                            </ul>
                          </div>
                        );
                      } else {
                        // Regular paragraphs
                        return (
                          <p key={index} className="text-gray-300 mb-4 leading-relaxed">
                            {paragraph}
                          </p>
                        );
                      }
                    })}
                  </div>
                </div>
              </article>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default BlogModal;